#page-preloader .browser-screen-loading-content {
    text-align: center;
    height: 2em;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 3em;
    left: 0;
    margin: auto
}

#page-preloader .loading-dots {
    margin-left: -1.5em;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: loading-dots-fadein .5s linear forwards;
    -moz-animation: loading-dots-fadein .5s linear forwards;
    -o-animation: loading-dots-fadein .5s linear forwards;
    animation: loading-dots-fadein .5s linear forwards
}

#page-preloader .loading-dots i {
    width: .5em;
    height: .5em;
    display: inline-block;
    vertical-align: middle;
    background: #e0e0e0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0 .125em;
    -webkit-animation: loading-dots-middle-dots .5s linear infinite;
    -moz-animation: loading-dots-middle-dots .5s linear infinite;
    -o-animation: loading-dots-middle-dots .5s linear infinite;
    animation: loading-dots-middle-dots .5s linear infinite
}

#page-preloader .loading-dots.dark-gray i {
    background: #707070
}

#page-preloader .loading-dots i:first-child {
    -webkit-animation: loading-dots-first-dot .5s infinite;
    -moz-animation: loading-dots-first-dot .5s linear infinite;
    -o-animation: loading-dots-first-dot .5s linear infinite;
    animation: loading-dots-first-dot .5s linear infinite;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translate(-1em);
    -moz-transform: translate(-1em);
    -o-transform: translate(-1em);
    -ms-transform: translate(-1em);
    transform: translate(-1em)
}

#page-preloader .loading-dots i:last-child {
    -webkit-animation: loading-dots-last-dot .5s linear infinite;
    -moz-animation: loading-dots-last-dot .5s linear infinite;
    -o-animation: loading-dots-last-dot .5s linear infinite;
    animation: loading-dots-last-dot .5s linear infinite
}

@-moz-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-webkit-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        -webkit-filter: none;
        filter: none
    }
}

@-o-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        -webkit-filter: none;
        filter: none
    }
}

@-moz-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-webkit-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        -webkit-filter: none;
        filter: none
    }
}

@-o-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        -webkit-filter: none;
        filter: none
    }
}

@-moz-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-webkit-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-o-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-moz-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "alpha(opacity=0)";
        filter: alpha(opacity=0)
    }
}

@-webkit-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "alpha(opacity=0)";
        filter: alpha(opacity=0)
    }
}

@-o-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "alpha(opacity=0)";
        filter: alpha(opacity=0)
    }
}

@keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "alpha(opacity=0)";
        filter: alpha(opacity=0)
    }
}