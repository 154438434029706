.progress-container {
    width: 100%;
    height: 3px;
    background: transparent;
    position: fixed;
    z-index: 5;

    .progress-bar {
        height: 3px;
        background: #000;
        width: 0%;
    }
}

.single_series_page {
    margin-top: 100px;
    padding-bottom: 40px;

    .gallery {
        width: 100%;
    }
    
    .text {
        h1 {
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 1.1px;
            margin-bottom: 0;
        }

        span {
            font-weight: 300;
            font-size: 12px;
            color: #999;
        }

        p {
            letter-spacing: 0px;
            display: block;
            margin-top: 12px;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
        }
    }

    .gallery_item {
        img {width: 100%;}
        img, a {
            cursor: pointer;
        }
    }

    .single_series-content {
        display: flex;

        .text {
            h1 {
                font-size: 30px;
                line-height: 130%;
            }
        }

        /* .gallery {
            // width: calc(100% - 250px);
            width: 65%;
            .gallery_item {
                margin-bottom: 80px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }

                p {
                    margin-top: 15px;
                }
            }
        } */
    }

    .single_series_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 120px 0 0;

        li {
            // width: 33.333%;
            width: 50%;

            span {
                font-weight: 500;
                font-size: 16px;
                cursor: pointer;
                position: relative;

                svg {
                    position: absolute;
                    top: -1px;
                    width: 11px;
                    transition: all .2s;

                    path {
                        stroke: #999;
                        transition: all .2s;
                    }
                }
            }

            &.toTop {
                position: relative;
                // display: flex;
                display: none;
                justify-content: center;
                align-items: center;

                span {
                    position: relative;
                    bottom: 33px;
                    transform: rotate(-90deg);
                    white-space: nowrap;

                    &:after {
                        content: '';
                        width: 8px;
                        height: 2px;
                        position: absolute;
                        background-color: #000;
                        top: 7px;
                        left: -14px;
                        opacity: .4;
                    }

                    svg {
                        position: absolute;
                        right: -20px;
                        transition: all .2s;
                        opacity: .4;
                        transition: all .2s;

                        polyline {
                            stroke: #000;
                        }
                    }

                    &:hover {
                        &:after {
                            opacity: 1;
                        }

                        svg {
                            opacity: 1;
                            animation: hover-top .7s infinite ease-in-out alternate;
                        }
            
                        @keyframes hover-top {
                            100% { transform: translate(10px, 0); }
                        }
                    }
                }
            }

            &:first-child {
                text-align: left;
                span {
                    padding-left: 20px;
                    svg {
                        left: 0;
                    }

                    &:hover {
                        svg {
                            // left: -5px;
                            path {
                                stroke: #000;
                            }

                            animation: hover-left .7s infinite ease-in-out alternate;
                        }
            
                        @keyframes hover-left {
                            100% { transform: translate(-10px, 0); }
                        }
                    }
                }
            }

            &:last-child {
                text-align: right;

                span {
                    padding-right: 20px;
                    svg {
                        right: 0;
                    }

                    &:hover {
                        svg {
                            // right: -5px;
                            path {
                                stroke: #000;
                            }

                            animation: hover-right .7s infinite ease-in-out alternate;
                        }

                        @keyframes hover-right {
                            100% { transform: translate(10px, 0); }
                        }
                    }
                }
            }
        }
    }
}

.SRLCaptionText {
    font-family: '700';
    text-transform: uppercase;
}