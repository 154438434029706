@import '../../styles/var.scss';

nav {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    align-items: baseline;
    padding-top: 50px;

    @media(max-width: $mobile) {
        align-items: center;
    }

    .logo {
        a {
            // font-family: '900';
            position: relative;
            font-weight: 700;
            letter-spacing: 1.8px;
            font-size: 22px;

            &.active {
                pointer-events: none;
            }
        }
    }

    .hamburger {
        display: none;
        @media(max-width: $mobile) {display: block;}
        width: 25px;
        height: 24px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        z-index: 2;
    
        span {
            background: #000;
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            right: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
            
    
            &:nth-child(1) {
                width: 25px;
                top: 0px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }
            &:nth-child(2) {
                width: 20px;
                top: 10px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }
            &:nth-child(3) {
                width: 23px;
                top: 20px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }
        }
        &.open span {
            &:nth-child(1) {
                width: 25px;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 0px;
                left: 1px;
            }
            &:nth-child(2) {
                width: 25px;
                position: relative;
                opacity: 0;
            }
            &:nth-child(3) {
                width: 25px;
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 18px;
                left: 1px;
            }
        }
    }

    ul {
        display: flex;
        align-items: center;

        li {
            margin-right: 40px;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1.4px;
            font-weight: 500;
            position: relative;

            &:last-child {
                margin-right: 0;

                a {
                    &:after {
                        display: none;
                    }
                }
            }

            

            a {
                position: relative;
                z-index: 2;

                &:after {
                    content: '';
                    width: 0px;
                    height: 8px;
                    position: absolute;
                    left: -2px;
                    bottom: -2px;
                    background-color: #ffdd00;
                    transition: width .2s;
                    z-index: -1;
                }
    
                &:hover {
                    &:after {
                        width: calc(100% + 4px);
                    }
                }

                &.active {
                    pointer-events: none;
                    &:after {
                        width: calc(100% + 4px);
                    }
                }
            }
        }

        @media(max-width: $mobile) {
            position: absolute;
            top: -100%;
            left: 0;
            width: 100%;
            background: #fff;
            display: block;
            padding: 50px 30px 30px;
            transition: all .2s;
            opacity: 0;
            z-index: -1;

            li {
                margin-right: 0;
                margin-bottom: 25px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.active {
                top: 0;
                opacity: 1;
                z-index: 1;
                transition: all .1s;
            }
        }
    }
}



.switcher {
    height: 13px;
    background: #000;
    width: 24px;
    border-radius: 60px;
    position: relative;
    cursor: pointer;

    .button {
        position: absolute;
        width: 9px;
        height: 9px;
        background: #fff;
        top: 2px;
        left: 2px;
        border-radius: 5px;
        transition: left 0.2s ease-in;
    }
}