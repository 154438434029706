/* .home_scroller {
    .scroller {
        display: flex;
        min-width: 100%;
        min-height: 60vh;
        overflow-x: auto;

        .item {
            border: 1px solid black;
            margin: 5px;
            box-shadow: 3px 5px rgba(255,255,255,0.05);

            img {
                display: block;
                // max-width: 100%;
                max-width: 100%;
                height: 50vh;
            }
        }
    }

    .scroller::-webkit-scrollbar {
        display: none;
    }
} */
.home_scroller {
    width: 100%;
    max-width: 1800px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0px;
    
    .swiper-container {
        margin-top: 17vh;
        padding: 0 30px;
        

        .item {
            width: auto;
            cursor: pointer;

            .item_img {
                height: 50vh;
                overflow: hidden;

                img {
                    display: block;
                    width: auto;
                    height: 100%;
                    transition: transform 1s;
                }
            }

            .item_title {
                ul {
                    margin-top: 34px;
                }

                li {
                    &:first-child {
                        font-size: 20px;
                        font-weight: 400;
                        span {
                            position: relative;
                            z-index: 1;
                            
                            &:after {
                                content: '';
                                width: 0px;
                                height: 10px;
                                position: absolute;
                                left: -2px;
                                bottom: -2px;
                                background-color: #ffdd00;
                                transition: width .2s;
                                z-index: -1;
                            }
                        }
                    }

                    &:last-child {
                        margin-top: 7px;
                        font-size: 12px;
                        font-weight: 300;
                        color: #999;
                    }
                }
                p {
                    text-align: left;
                    margin-top: 34px;
                    font-size: 20px;
                    padding-bottom: 4px;
                    font-weight: 400;
                    // letter-spacing: 1.2px;

                    strong {
                        position: relative;
                        z-index: 1;
                        // font-family: '300';
                    }
                }

                
            }
            
            &:hover {
                .item_img {
                    img {
                        transform: scale(1.1);
                        transition: transform 4s;
                    }
                }
                
                .item_title {
                    li {
                        &:first-child {
                            span {
                                &:after {
                                    width: calc(100% + 4px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
