@import '../../styles/var.scss';

.single_series_page {
    &.two_columns {
        padding: 0 100px;
        @media(max-width: 1240px) {padding: 0 80px;}
        @media(max-width: 1110px) {padding: 0 70px;}
        @media(max-width: 960px) {padding: 0 60px;}
        @media(max-width: $mobile) {padding: 0 30px;}

        .single_series-content {
            max-width: 1300px;
            margin: 0 auto;
            
            .gallery {
                display: flex;
                justify-content: space-between;

                &.gallery_mob {
                    display: flex;
                    flex-wrap: wrap;

                    .gallery_item {
                        width: 46%;

                        @media(max-width: $mobile) {
                            width: 100%;
                        }
                    }
                }
            }

            .gallery_left {
                width: 46%;

                @media(max-width: $mobile) {
                    width: 100%;
                }
            }

            .gallery_right {
                width: 46%;

                @media(max-width: $mobile) {
                    width: 100%;
                }
            }

            .gallery_item {
                margin-bottom: 100px;
            }

            @media(max-width: $mobile) {
                .gallery {
                    flex-direction: column;
                }

                .gallery_item {
                    width: 100%;
                    margin-bottom: 50px;
                }
            }
        }
    }
}