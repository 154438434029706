// @mixin font-face($font-family, $file-path) {
//     @font-face {
//         font-family: $font-family;
//         font-display: swap;
//         font-weight: normal;
//         font-style: normal;

//         src: url('#{$file-path}.eot');
//         src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
//         url('#{$file-path}.woff') format('woff'),
//         url('#{$file-path}.ttf') format('truetype'),
//         url('#{$file-path}.svg##{$font-family}') format('svg');
//     }
// }

// @include font-face('100', '../fonts/Museo-Sans-Cyr/MuseoSansCyrl-100');
// @include font-face('300', '../fonts/Museo-Sans-Cyr/MuseoSansCyrl-300');
// @include font-face('500', '../fonts/Museo-Sans-Cyr/MuseoSansCyrl-500');
// @include font-face('700', '../fonts/Museo-Sans-Cyr/MuseoSansCyrl-700');
// @include font-face('900', '../fonts/Museo-Sans-Cyr/MuseoSansCyrl-900');



// @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&display=swap');

html {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.wrapper {
    width: 100%;
    max-width: 1800px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
}

/* .switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
} */