.about_page {
    margin-top: 100px;
    max-width: 800px;
    margin-left: 0px;

    img {
        display: block;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 20px;
        line-height: 150%;
    }

    .about_page-list {
        margin-bottom: 20px;
        p {
            margin-bottom: 10px;
            font-weight: 600;
        }

        li {
            margin-bottom: 10px;
            line-height: 150%;

            &:last-child {margin-bottom: 0;}
        }
    }
}