.single_series_page {
    &.single {
        .single_series-content {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .gallery {
                a, img {cursor: default;}
            }
        }
        
    }
}