@import '../../styles/var.scss';

.single_series_page {
    &.right_column {
        .single_series-content {
            display: flex;

            .text {
                width: 35%;
                // font-family: '700';
                padding-right: 40px;
                position: relative;

                .sticky {
                    position: sticky;
                    top: 20px;
                }
            }

            .gallery {
                // width: calc(100% - 250px);
                width: 65%;
                .gallery_item {
                    margin-bottom: 80px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }

                    p {
                        margin-top: 15px;
                    }
                }
            }

            @media(max-width: $mobile) {
                flex-direction: column;

                .text {
                    width: 100%;
                    padding-right: 0;
                    margin-bottom: 40px;
                }

                .gallery {
                    width: 100%;
                }
            }
        }
    }
}