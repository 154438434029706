.app {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #fff;
    // font-family: '300';
    transition: background .2s;
    padding-bottom: 40px;

    h1, h2, h3, h4, h5, h6, a, p, li, span {
        color: #000;
        transition: color .2s;
    }

    &.dark {
        background: #000;
        transition: background .2s;
        
        h1, h2, h3, h4, h5, h6, a, p, li, span {
            color: #fff;
            transition: color .2s;
        }

        .switcher {
            background: #fff;
            transition: all .2s;
            .button {
                left: 13px;
                background: #000;
            }
        }

        nav {
            @media(max-width: 600px) {
                ul {
                    background-color: #000;
                }
            }

            .hamburger {
                span {
                    background: #fff;
                }
            }
        }

        .progress-container {
            .progress-bar {
                background: #fff;
            }
        }

        .single_series_page {
            .single_series_nav {
                li {
                    span {
                        &:hover {
                            svg {
                                path {
                                    stroke: #fff;
                                }
                            }
                        }
                    }

                    &.toTop {
                        span {
                            &:after {
                                background-color: #fff;
                            }
        
                            svg {
                                polyline {
                                    stroke: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}