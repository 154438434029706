/* .contactEmail { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.contact_page {
    margin-top: 100px;

    ul {
        li {
            margin-bottom: 20px;
        }
    }

    a {
        position: relative;
        z-index: 2;

        &:after {
            content: '';
            width: 0px;
            height: 8px;
            position: absolute;
            left: -2px;
            bottom: -2px;
            background-color: #ffdd00;
            transition: width .2s;
            z-index: -1;
        }

        &:hover {
            &:after {
                width: calc(100% + 4px);
            }
        }
    }
}